/*
Template Name: Minible - Admin & Dashboard Template
Author: Themesbrand
Version: 2.6.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

@import "variables-dark";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/root";
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/alerts";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";


// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";;
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/repeater";
@import "custom/plugins/form-editors";
@import "custom/plugins/xeditable";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/datatable";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/file-manager";
@import "custom/pages/extras-pages";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
.AppName{
    margin-top: 15px;
}
.app-card-container2:hover {
  background-color:#f8f9fa;
  color: black;
}
.app-card-container2 {
  color: #f8f9fa;
}

.btnparent {
  text-align: center;

  // Default styles for child
  .keywordbtn {
    transition: background-color 0.3s ease; // Smooth transition
  }

  // Change child color when parent is hovered
  &:hover .keywordbtn {
    color: white;
  }
}
table td, table th {
  vertical-align: middle !important; /* Ensure vertical middle alignment */
}
// .card-dashed-border {
//   border: 2px dashed #000; /* Customize the border style */
// }
.sidecol{
  color:gray;
}

.app-card-container {
  position: relative;
}

.card-content {
  transition: filter 0.3s ease, opacity 0.3s ease;
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(251, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.remove-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.app-card-container:hover .card-content {
  filter: blur(3px);
  opacity: 0.3;
}

.app-card-container:hover .hover-overlay {
  opacity: 1;
}

.position-relative {
  position: relative;
  //display: inline-block; /* Ensures container dimensions match content */
}

.unlock-btn {
  top: 5%;
  left: 50%;
  //transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: auto; /* Allows button interaction over blurred content */
 // margin-bottom: 2;
}

.blurdiv {
  filter: blur(5px); /* Adjust blur level as needed */
}
